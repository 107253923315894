@import "~app/mixins";

$border-color: #dfe1e4;
.flexWrapBox {
  display: flex;
  flex-wrap: wrap;
}

.aduCalculatorModal {
  .btnClose {
    right: 40px;
    top: 38px;
    padding: 0px;
    border-radius: 999px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;

    &:hover {
      background: var(--web-grey-200, #dfe1e4);
    }
    &:active {
      background: var(--web-grey-300, #bcc1c7);
    }
    span {
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 24px;
    .headerTitle {
      color: #3c4654;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -0.72px;
      font-family: $font-inter;
    }
    .clsSupTitle {
      color: #66707d;
      font-size: 14px;
      font-weight: 500;
      font-family: $font-inter;
    }
    .clsCalc {
      padding-right: 70px;
      margin-top: -5px;
      p {
        color: #66707d;
        font-size: 14px;
        font-weight: 500;
        font-family: $font-inter;
        margin: 0;
      }
    }
  }
}

.aduContainer {
  margin: 0;
  .clsType {
    color: #3c4654;
    font-size: 14px;
    font-weight: 600;
    font-family: $font-inter;
  }

  .aduTypeLabel {
    min-height: 30px;
    text-align: center;
    color: #66707d;
    font-family: $font-inter;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    display: flex;
    align-items: center;
  }

  .clsIconsList {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    li {
      list-style: none;
      padding-right: 10px;
      margin-bottom: 15px;
      flex: 1 0 0px;
      &:last-child {
        padding-right: 0;
      }
      .clsIconBox {
        position: relative;
        
        p {
          color: #66707d;
          font-size: 18px;
          font-family: $font-inter;
          font-weight: 600;
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        } 
      }

      .svgIcon { 
        border-radius: 12px; 
        box-shadow: 0px 0px 0px 1px #bcc1c7;
        background: #f3f5f8; 
        overflow: hidden;
        width: 110px;
        height: 100px;
        cursor: pointer;
        div {
          display: block;
          width: 100%; 
          height: 100%;
        }
        svg {
          width: 100%; 
          height: 100%;
        }
        &.iconActive { 
          box-shadow: 0px 0px 0px 2.5px #128292;
        }
      }
    }
  }

  .clsInterBox {
    padding: 15px;
    border-radius: 12px;
    background: #f3f5f8;
    margin-bottom: 15px;
    p {
      color: #66707d;
      font-size: 14px;
      font-family: $font-inter;
      font-weight: 400;
      margin-bottom: 0;
      span {
        color: #3c4654;
        font-weight: 600;
      }
    }
  }

  .clsBtnGrp {
    margin-bottom: 20px;
    .clsBtnTitle {
      color: #3c4654;
      font-size: 14px;
      font-weight: 600;
      font-family: $font-inter;
    }
    .btn-group {
      margin-left: 0px !important;
    }

    .clsBtn {
      border-radius: 999px !important;
      box-shadow: inset 0px 0px 0px 1px #bcc1c7;
      background: #fff;
      color: #66707d;
      font-size: 14px;
      font-family: $font-inter;
      font-weight: 600;
      margin-left: 10px;
      outline: none;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;

      &:first-child {
        margin-left: 0;
      }

      &.activeType {
        box-shadow: inset 0px 0px 0px 2px #128292;
        color: #128292 !important;
        .clsBtnCheck {
          background: #128292;
          svg {
            display: block;
          }
        }
      }

      .clsBtnCheck {
        border-radius: 999px;
        background: #dfe1e4;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #fff;
          display: none;
        }
      }

      &.minW70 {
        min-width: 70px;
      }
      &.minW95 {
        min-width: 95px;
      }
      &.minW100 {
        justify-content: flex-start;
        min-width: 100px;
        padding-left: 7px;
      }
    }
  }
}

.overview {
  border-radius: 8px;
  background: #f3f5f8;
  color: #3C4654;
  display: flex;
  min-height: 480px;
  padding: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  .overviewHeading {
    margin: 0;
    h1 {
      font-size: 24px;
      font-family: $font-inter;
      max-width: 350px;
      text-align: center;
      margin: 0;
      color: var(--web-grey-600, #66707d);
    }
  }

  .note {
    border-radius: 12px;
    background: #fff;
    padding: 20px 30px;
    color: #99a0aa;
    max-width: 480px;
    h4 {
      font-size: 24px;
      font-family: $font-inter;
      font-weight: 600;
      letter-spacing: -0.48px;
      display: inline-flex;
      align-items: center;
      .svgIcon {
        margin-right: 5px;
      }
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      font-family: $font-inter;
    }
  }

  &.clsChartOverview {
    padding: 36px;
    display: block;
    .note {
      background-color: transparent;
      max-width: 100%;
      padding: 0;
      h4 {
        font-size: 16px;
        font-family: $font-inter;
        .svgIcon {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      p {
        font-size: 14px;
        font-family: $font-inter;
      }
    }
    .clsChartLft {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: -13%;
    }
    .clsChartLegend {
      border-top: solid 1px #bcc1c7;
    }
    .clsChartRit {
      padding-top: 25px;
      .clsChartValueList {
        list-style: none;
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;
        padding-right: 10px;

        h6 {
          color: #3c4654;
          font-size: 16px;
          font-family: $font-inter;
          font-weight: 600;
          margin: 0;
          margin-bottom: 5px;
        }
        p {
          color: #66707d;
          font-size: 14px;
          font-family: $font-inter;
          font-weight: 500;
          margin: 0;
          span {
            border-left: solid 2px #99a0aa;
            margin: 0 5px;
          }
        }

        &::after {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 999px;
          background: #66707d;
          position: absolute;
          top: 3px;
          left: 0;
        }

        &:nth-of-type(1) {
          &::after {
            background: #D34F0D;
          }
        }
        &:nth-of-type(2) {
          &::after {
            background: #F16722;
          }
        }
        &:nth-of-type(3) {
          &::after {
            background: #F58D5C;
          }
        }
        &:nth-of-type(4) {
          &::after {
            background: #FFC8AC;
          }
        }
        &:nth-of-type(5) {
          &::after {
            background: #0E6673;
          }
        }
        &:nth-of-type(6) {
          &::after {
            background: #128292;
          }
        }
        &:nth-of-type(7) {
          &::after {
            background: #53A6B2;
          }
        }
        &:nth-of-type(8) {
          &::after {
            background: #94C9D1;
          }
        }
        &:nth-of-type(9) {
          &::after {
            background: #DE9C1E;
          }
        }
        &:nth-of-type(10) {
          &::after {
            background: #F4AC21;
          }
        }
        &:nth-of-type(11) {
          &::after {
            background: #F6BA47;
          }
        }
        &:nth-of-type(12) {
          &::after {
            background: #F9D081;
          }
        }
        &:nth-of-type(13) {
          &::after {
            background: #5B68C3;
          }
        }
        &:nth-of-type(14) {
          &::after {
            background: #747FCD;
          }
        }
        &:nth-of-type(15) {
          &::after {
            background: #929BD9;
          }
        }
        &:nth-of-type(16) {
          &::after {
            background: #AFB6E5;
          }
        }
        &:nth-of-type(17) {
          &::after {
            background: #CA1551;
          }
        }
      }
    }
  }
}

.p_0 {
  padding: 0;
}
.aduCalculatorModal {
  .header {
    margin-bottom: 0px;
  }
}
.disclaimerModal {
  font-family: $font-inter;
  .header {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin: 1em;
  }
  .body {
    text-align: justify;
    margin: 0 2em;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 90;

  &.hidden {
    display: none;
  }

  svg {
    width: 100px;
    height: 100px;

    use {
      fill: #5394f5;
    }
  }
}

.clsBodyRow {
  margin: 0;
  .clsBodyLft {
    padding: 0 24px;
  }
  .clsBodyRight {
    padding: 0 8px 8px 0;
  }
}
.mar0 {
  margin: 0;
}
.pad0 {
  padding: 0;
}
